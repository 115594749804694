import React from 'react';
import { graphql } from 'gatsby';
import BlogPostGrid from '../components/Blog/BlogPostGrid';
import ProductTypeGrid from '../components/Blog/ProductTypeGrid';

export default function Blogs({ data }) {
  const blog_posts = data.allStrapiArticles.edges;
  const cat_posts = data.allStrapiProducts.edges;

  return (
    <>
      <BlogPostGrid blogPosts={blog_posts} />
      <ProductTypeGrid blogPosts={cat_posts} />
    </>
  );
}

export const query = graphql`
  query {
    allStrapiArticles {
      edges {
        node {
          id
          Title
          slug
          PublishedDate
          Preview_Text
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
      }
    }

    allStrapiProducts {
      edges {
        node {
          Hero_Product {
            Title
            Background_Image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          slug
          id
        }
      }
    }
  }
`;

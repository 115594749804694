import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { BgImage } from 'gbimage-bridge';

import { ImArrowUpRight2 } from 'react-icons/im';

const MOBILE_BREAKPOINT = '768px';

const Container = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px 30px 0;
  box-sizing: border-box;
  border-radius: 3px;
  transition: all 0.3s;
  cursor: pointer;

  .arrow-icon {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 25px;
    color: white;
  }

  & > * {
    transition: all 0.3s;
  }

  &:hover {
    background: linear-gradient(260.48deg, #a1c654 54.34%, #b0e640 95.23%);
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 10px 10px 0;

    .arrow-icon {
      right: 10px;
      bottom: 10px;
    }
  }
`;

const Title = styled.h3`
  margin-bottom: 25px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
  color: #ffffff;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 36px;
  }
`;

const PublishedDate = styled.div`
  margin-top: 5px;
  margin-bottom: calc(80px + 5vw);
  font-family: 'Oswald';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 132.5%;
  color: white;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 16px;
  }
`;

export default function BlogPost({ bgImage, blogTitle, publishedDate, link }) {
  return (
    <BgImage
      image={bgImage}
      preserveStackingContext
      style={{
        width: '100%',
        backgroundPosition: 'center',
        borderRadius: '3px',
        overflow: 'hidden',
      }}
    >
      <Container to={link}>
        <Title>{blogTitle}</Title>
        <PublishedDate>{publishedDate}</PublishedDate>
        <ImArrowUpRight2 className="arrow-icon" />
      </Container>
    </BgImage>
  );
}

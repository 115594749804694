import React from 'react';
import styled from 'styled-components';

import BlogPost from './BlogPost';

const MOBILE_BREAKPOINT = '992px';

const Container = styled.div`
  margin: 70px 0 120px;
  padding: 0 50px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    margin: 50px 0 60px;
    padding: 0 20px;
  }
`;

const Title = styled.h2`
  padding: 0 20px;
  margin-bottom: 70px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
  color: #2d2b2c;

  @media screen and (max-width: 768px) {
    margin-bottom: 45px;
    font-size: 24px;
  }
`;

const PostGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);
  gap: 20px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: minmax(0, 1fr);
    gap: 30px;
  }
`;

export default function BlogPostGrid({ blogPosts }) {
  return (
    <Container>
      <Title>LATEST BLOG POSTS</Title>
      <PostGrid>
        {blogPosts.map((post) => {
          const { id, BannerImage, Title, PublishedDate, slug } = post.node;

          return (
            <BlogPost
              id={id}
              bgImage={BannerImage.localFile.childImageSharp.gatsbyImageData}
              blogTitle={Title}
              publishedDate={PublishedDate}
              link="/menu"
            />

            /* {`/blog/${slug}`} */
          );
        })}
      </PostGrid>
    </Container>
  );
}
